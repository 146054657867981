import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { LoyaltyProgramMembershipPresenter } from '@/app/ui/presenters/LoyaltyProgramMembershipPresenter'
import { ErrorResponse } from '@/domain/entities/BaseResponse'
import {
  DetailLoyaltyProgramMembership,
  ListLoyaltyProgramMembership,
} from '@/domain/entities/LoyaltyProgramMembership'
import { Utils } from '@/app/infrastructures/misc'
import {
  CreateLoyaltyProgramMembershipRequest,
  UpdateLoyaltyProgramMembershipRequest,
} from '@/data/payload/api/LoyaltyProgramMembershipRequest'
import dayjs from 'dayjs'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import { QueryParamsMembership } from '@/app/infrastructures/misc/Constants/loyaltyProgram'

interface state {
  isLoading: boolean
  listData: ListLoyaltyProgramMembership
  detailLoyaltyMembership: DetailLoyaltyProgramMembership
  errResponse: string
}

export interface FormLevelInterface {
  name: string
  minAmount: number
  minNumber: number | null
  id?: number
}

export interface FormDate {
  date: string
  month: string
}

export interface CreateLoyaltyProgramMembershipPayload {
  sttPrefix: Array<string>
  shipmentID: Array<string>
  isNonCA: boolean
  packageStatus: Array<string>
  month: number | string
  date: FormDate[]
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  formLevel: FormLevelInterface[]
}

export interface UpdateLoyaltyProgramMembershipPayload {
  id: number
  body: UpdateLoyaltyProgramMembershipRequest
}
@Module({
  namespaced: true,
  store,
  name: 'loyaltyProgramMembership',
  dynamic: true,
})
class LoyaltyProgramMembershipController extends VuexModule implements state {
  private presenter: LoyaltyProgramMembershipPresenter = container.resolve(
    LoyaltyProgramMembershipPresenter
  )

  // START state
  isLoading = false
  listData: ListLoyaltyProgramMembership = new ListLoyaltyProgramMembership()
  detailLoyaltyMembership: DetailLoyaltyProgramMembership = new DetailLoyaltyProgramMembership()
  errResponse = ''
  errSave = ''
  isSuccessSave = false
  // END state

  @Action({ rawError: true })
  public getAll(params: QueryParamsMembership) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(val => {
        this.setListData()
        this.setListData(val)
      })
      .catch(err => {
        const error: ErrorResponse = err.error
        this.setErrResponse(error.message?.en || '')
        this.setListData()
        Vue.notify({
          title: 'Fetch List Loyalty Program Membership Error',
          text: error.message?.en || 'Something Went Wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getOne(id: number) {
    this.setLoading(true)

    this.presenter
      .getOne(id)
      .then(val => {
        this.setDetailData(val)
      })
      .catch(err => {
        const error: ErrorResponse = err.error
        this.setErrResponse(error.message?.en || '')
        Vue.notify({
          title: 'Fetch Detail Loyalty Program Membership Error',
          text: error.message?.en || 'Something Went Wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: CreateLoyaltyProgramMembershipPayload) {
    this.setLoading(true)
    this.setSuccessSave(false)

    this.presenter
      .create(
        new CreateLoyaltyProgramMembershipRequest(
          {
            start_date: dayjs(
              `${payload.startDate} ${payload.startTime}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format(),
            end_date: dayjs(
              `${payload.endDate} ${payload.endTime}`,
              'YYYY-MM-DD HH:mm:ss'
            ).format(),
            prefix_stt_shipments: payload.sttPrefix,
            prefix_shipment_ids: payload.shipmentID,
            is_include_stt_non_ca: payload.isNonCA,
            package_statuses: payload.packageStatus,
            backward_month_period: payload.month,
          },
          payload.date.map(item => {
            return {
              date: Number(item.date),
              month: Number(item.month),
            }
          }),
          payload.formLevel.map((item: FormLevelInterface) => {
            return {
              level_name: item.name,
              min_amount_transaction: item.minAmount,
              min_number_transaction: item.minNumber,
            }
          })
        )
      )
      .then(() => {
        this.setSuccessSave(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Loyalty Program Membership',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setSuccessSave(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: UpdateLoyaltyProgramMembershipPayload) {
    this.setLoading(true)
    this.setErrSave('')

    this.presenter
      .update(payload.id, payload.body)
      .then(() => {
        this.setErrSave(EventBusConstants.CREATE_LOYALTY_PROGRAM_MEMBERSHIP)
      })
      .catch(error => {
        const errSave =
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong'
        this.setErrSave(errSave)
        Vue.notify({
          title: 'Update Loyalty Program Membership',
          text: errSave,
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }
  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setErrResponse(err: string) {
    this.errResponse = err
  }

  @Mutation
  private setErrSave(err: string) {
    this.errSave = err
  }

  @Mutation
  private setSuccessSave(err: boolean) {
    this.isSuccessSave = err
  }

  @Mutation
  private setListData(
    data: ListLoyaltyProgramMembership = new ListLoyaltyProgramMembership()
  ) {
    this.listData = data
  }

  @Mutation
  private setDetailData(data: DetailLoyaltyProgramMembership) {
    this.detailLoyaltyMembership = data
  }
}

export default getModule(LoyaltyProgramMembershipController)
