







































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import iconConfirmation from '@/app/ui/assets/icon_confirmation.vue'

@Component({
  components: {
    Modal,
    Button,
    iconConfirmation,
  },
})
export default class ModalConfirmation extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ required: true }) private title!: string
  @Prop({ required: true }) private subtitle!: string
  @Prop({ default: 'Yes' }) private buttonOK!: string
  @Prop() private styleBtnCancel!: string
  @Prop() private styleBtnOk!: string

}
