


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MembershipTextInput from '../MembershipTextInput/index.vue'
import OptionBox from '@/app/ui/components/OptionBox/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'

export interface IErrorMessage {
  name: string | undefined
  minAmount: string
}

@Component({
  components: {
    MembershipTextInput,
    OptionBox,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
  },
})

export default class FormLevel extends Vue {
  @Prop({ required: false }) private index!: number
  @Prop() private errorMessageName!: string | undefined
  @Prop() private errorMessageMinAmount!: string | undefined
  @Prop({ default: true }) private disabledMinNumber!: boolean
  @Prop( { default: false }) private makeDisabled!: boolean
  @Prop( { default: false }) private isEditMode!: boolean

  // isValidate when user click save button going to be true
  // when user input on this form level or toggle open/close it going to be false
  @Prop( { default: false }) private isValidate!: boolean

  isCheckMinAmount = false
  // action for user click on card level
  isActionClick = false

  @Watch('$attrs')
  onChangeDataDetail(val: Record<string, string | Record<string, unknown>>): void {
    if (!this.isActionClick && this.isEditMode || this.isValidate) {
      this.isCheckMinAmount = this.hasMinVal(val, 'minAmount')
    }
  }

  private submitName(val: string) {
    this.$emit('update:levelName', val, this.index)
  }

  private submitMinAmount(val: number): void {
    this.$emit('update:levelMinAmount', val, this.index)
  }

  private toggleCheckMinAmount(): void {
    this.isActionClick = true
    if (!this.makeDisabled) {
      this.isCheckMinAmount = !this.isCheckMinAmount
      this.$emit('update:levelMinAmount', null, this.index)
    }
  }

  private hasMinVal($attrs: Record<string, string | Record<string, unknown>>, key: string): boolean {
    if ($attrs.value) {
      if (typeof $attrs.value === 'object') {
        return this._isNotNanAndHasVal(<number>$attrs.value[key])
      }
    }
    return false
  }

  private _isNotNanAndHasVal(val: number): boolean {
    if (isNaN(<number>val)) {
      return false
    }
    return val >= 0
  }

  private onFocus() {
    this.$emit('focus', this.index)
  }

}
