var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModalConfirmation',{ref:"loyaltyMembership-create-modal-confirm",attrs:{"visible":_vm.modalConfirmation.visible,"title":_vm.modalConfirmation.title,"subtitle":_vm.modalConfirmation.subtitle,"buttonOK":_vm.modalConfirmation.buttonOK},on:{"close":function($event){return _vm.closeModal()},"submit":function($event){return _vm.onSubmit()}}}),_c('h1',{staticClass:"title"},[_vm._v(" Create Membership Program ")]),_c('div',{staticClass:"flex gap-x-2"},[_c('div',{staticClass:"flex flex-col gap-x-2"},[_c('h2',{staticClass:"sub-title mb-4"},[_vm._v(" Membership General Rules ")]),_c('div',{staticClass:"wrapper-section border border-tableRowEven rounded-lg pt-6"},[_c('h3',{staticClass:"title-section mb-6"},[_vm._v(" Transaction Type ")]),_c('div',{staticClass:"flex flex-col gap-y-6"},[_c('MembershipTextInput',{staticClass:"w-full max-w-125",attrs:{"type":'number',"id":"shipmentSttPrefix","fieldLabel":'Shipment STT Prefix',"required":true,"isMultiTag":"","canDelete":"","placeholder":"Input STT Prefix (ex. 88, 11, 10)","errorMessage":_vm.$v.form.sttPrefix.$invalid && _vm.hasChanged.sttPrefix
                ? 'Must be filled'
                : undefined},on:{"update:modelValue":function (val) {
                _vm.form.sttPrefix = val
                _vm.hasChanged.sttPrefix = true
              }},model:{value:(_vm.form.sttPrefix),callback:function ($$v) {_vm.$set(_vm.form, "sttPrefix", $$v)},expression:"form.sttPrefix"}}),_c('MembershipTextInput',{staticClass:"w-full max-w-125",attrs:{"id":"shipmentID","fieldLabel":'Shipment ID',"required":true,"isMultiTag":"","canDelete":"","placeholder":"Input shipment ID (ex. AD, AS, etc)","errorMessage":_vm.$v.form.shipmentID.$invalid && _vm.hasChanged.shipmentID
                ? 'Must be filled'
                : undefined},on:{"update:modelValue":function (val) {
                _vm.form.shipmentID = val
                _vm.hasChanged.shipmentID = true
              }},model:{value:(_vm.form.shipmentID),callback:function ($$v) {_vm.$set(_vm.form, "shipmentID", $$v)},expression:"form.shipmentID"}}),_c('div',{staticClass:"wrapper-section-non-ca",on:{"click":function($event){return _vm.toogleNonCA()}}},[(_vm.form.isNonCA)?_c('CheckBoxCheckedIcon'):_c('CheckBoxUncheckedIcon'),_vm._m(0)],1),_c('MembershipTextInput',{staticClass:"w-full max-w-125",attrs:{"id":"packageStatus","fieldLabel":'Package Status',"required":true,"isMultiTag":"","canDelete":"","placeholder":"Input package status (ex. BKD, POD, etc)","errorMessage":_vm.$v.form.packageStatus.$invalid && _vm.hasChanged.packageStatus
                ? 'Must be filled'
                : undefined},on:{"getError":function (bool) {
                return false
              },"update:modelValue":function (val) {
                _vm.form.packageStatus = val
                _vm.hasChanged.packageStatus = true
              }},model:{value:(_vm.form.packageStatus),callback:function ($$v) {_vm.$set(_vm.form, "packageStatus", $$v)},expression:"form.packageStatus"}})],1),_c('h3',{staticClass:"title-section mt-8 mb-6"},[_vm._v(" Conditions for Maintaining Level ")]),_c('MembershipTextInput',{ref:"rangeMonth",staticClass:"w-full max-w-78",attrs:{"type":"number","fieldLabel":'Check transaction for the last',"required":true,"fieldSuffix":'Months',"placeholder":"Input months","errorMessage":!_vm.$v.form.month.required && _vm.hasChanged.month
              ? 'Must be filled'
              : !_vm.$v.form.month.minValue && _vm.hasChanged.month
              ? 'can`t be 0'
              : undefined},on:{"input":function (e) {
              _vm.hasChanged.month = true
              _vm.NolIgnore(e)
            }},model:{value:(_vm.form.month),callback:function ($$v) {_vm.$set(_vm.form, "month", $$v)},expression:"form.month"}}),_c('div',{staticClass:"w-full"},[_vm._m(1),_c('div',{staticClass:"flex gap-x-3"},[_c('MembershipDatePicker',{attrs:{"id":"date-1","removeYear":true,"name":"date1","fieldLabel":'Date 1',"valueFormat":"date","formatted":"DD/MM","label":"DD/MM","withIcon":true,"errorMessage":_vm.isDateScheduleEmpty() && _vm.hasChanged.date1
                  ? 'Must be filled'
                  : _vm.hasDuplicateMonth('date1')
                  ? 'Data cannot duplicate'
                  : undefined},on:{"input":function () {
                  _vm.hasChanged.date1 = true
                }},model:{value:(_vm.form.date1),callback:function ($$v) {_vm.$set(_vm.form, "date1", $$v)},expression:"form.date1"}}),_c('MembershipDatePicker',{attrs:{"id":"date-2","removeYear":true,"name":"date2","fieldLabel":'Date 2',"valueFormat":"date","formatted":"DD/MM","label":"DD/MM","withIcon":true,"errorMessage":_vm.hasDuplicateMonth('date2')
                  ? 'Data cannot duplicate'
                  : undefined},on:{"input":function () {
                  _vm.hasChanged.date2 = true
                }},model:{value:(_vm.form.date2),callback:function ($$v) {_vm.$set(_vm.form, "date2", $$v)},expression:"form.date2"}}),_c('MembershipDatePicker',{attrs:{"id":"date-3","removeYear":true,"name":"date3","fieldLabel":'Date 3',"valueFormat":"date","formatted":"DD/MM","label":"DD/MM","withIcon":true,"errorMessage":_vm.hasDuplicateMonth('date3')
                  ? 'Data cannot duplicate'
                  : undefined},on:{"input":function () {
                  _vm.hasChanged.date3 = true
                }},model:{value:(_vm.form.date3),callback:function ($$v) {_vm.$set(_vm.form, "date3", $$v)},expression:"form.date3"}}),_c('MembershipDatePicker',{attrs:{"id":"date-4","removeYear":true,"name":"date4","fieldLabel":'Date 4',"valueFormat":"date","formatted":"DD/MM","label":"DD/MM","withIcon":true,"errorMessage":_vm.hasDuplicateMonth('date4')
                  ? 'Data cannot duplicate'
                  : undefined},on:{"input":function () {
                  _vm.hasChanged.date4 = true
                }},model:{value:(_vm.form.date4),callback:function ($$v) {_vm.$set(_vm.form, "date4", $$v)},expression:"form.date4"}})],1)])],1),_c('h2',{staticClass:"sub-title mt-8"},[_vm._v("Levelling Rules")]),_c('div',{staticClass:"wrapper-level border border-tableRowEven rounded-lg px-4 pt-6 pb-4"},[_c('MultiLevel',{ref:"multi-level-component",attrs:{"ObjectMultiLevel":_vm.ObjectMultiLevel},on:{"click":function (index) { return _vm.toggleMultiLevel(index); }},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('FormLevel',{ref:"multi-level-component__item-level",attrs:{"allowNullNumber":true,"index":slotProps.index,"errorMessageName":_vm.isLevelError(slotProps.index, 'name'),"errorMessageMinAmount":_vm.isLevelError(slotProps.index, 'minAmount'),"disabledMinNumber":true},on:{"update:levelName":function (val, index) { return _vm.onInputLevel(val, index, 'name'); },"update:levelMinAmount":function (val, index) { return _vm.onInputLevel(val, index, 'minAmount'); },"focus":function (index) { return _vm.openMultiLevel(index); }},model:{value:(_vm.form.levelRule[slotProps.index]),callback:function ($$v) {_vm.$set(_vm.form.levelRule, slotProps.index, $$v)},expression:"form.levelRule[slotProps.index]"}})]}}])})],1)]),_c('div',{staticClass:"border border-tableRowEven rounded-lg px-4 flex-auto mt-10"},[_c('h3',{staticClass:"title-section mb-6 mt-6"},[_vm._v("Period")]),_c('div',{staticClass:"flex gap-x-3"},[_c('MembershipDatePicker',{attrs:{"withIcon":true,"id":"start-date","min-date":_vm.todayDate,"name":"startDate","fieldLabel":'Start Date',"valueFormat":"date","required":true,"label":"DD/MM/YY","formatted":"DD/MM/YY","auto-close":true,"errorMessage":_vm.$v.form.startDate.$invalid && _vm.hasChanged.startDate
              ? 'Must be filled'
              : undefined},on:{"input":function () {
              _vm.hasChanged.startDate = true
              if (_vm.form.endDate < _vm.form.startDate) {
                _vm.form.endDate = ''
              }
            }},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}}),_c('MembershipDatePicker',{attrs:{"id":"start-time","name":"startTime","fieldLabel":'Start Time',"valueFormat":"time","required":true,"label":"HH:MM:SS","formatted":"HH:mm:ss","errorMessage":_vm.$v.form.startTime.$invalid && _vm.hasChanged.startTime
              ? 'Must be filled'
              : undefined},on:{"input":function () {
              _vm.hasChanged.startTime = true
            }},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1),_c('div',{staticClass:"flex gap-x-3"},[_c('MembershipDatePicker',{attrs:{"withIcon":true,"id":"end-date","min-date":_vm.moreStartDate,"name":"endDate","fieldLabel":'End Date',"valueFormat":"date","required":true,"label":"DD/MM/YY","formatted":"DD/MM/YY","auto-close":true,"errorMessage":_vm.$v.form.endDate.$invalid && _vm.hasChanged.endDate
              ? 'Must be filled'
              : undefined},on:{"input":function () {
              _vm.hasChanged.endDate = true
            }},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}}),_c('MembershipDatePicker',{attrs:{"id":"end-time","name":"endTime","fieldLabel":'End Time',"valueFormat":"time","required":true,"label":"HH:MM:SS","formatted":"HH:mm:ss","errorMessage":_vm.$v.form.endTime.$invalid && _vm.hasChanged.endTime
              ? 'Must be filled'
              : undefined},on:{"input":function () {
              _vm.hasChanged.endTime = true
            }},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)])]),_c('div',{staticClass:"wrapper-button"},[_c('Button',{ref:"button-cancel",staticClass:"button-style",attrs:{"buttonStyle":"outline","customPadding":""},on:{"click":function($event){return _vm.showModalCancel()}}},[_vm._v("Cancel ")]),_c('Button',{ref:"button-save",staticClass:"button-style",attrs:{"customPadding":""},on:{"click":function($event){return _vm.showModalSubmit()}}},[_vm._v("Start Program")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"label-non-ca"},[_vm._v("Include without shipment ID")]),_c('span',{staticClass:"desc-non-ca"},[_vm._v(" (Non-CA shipments)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title-set-schedule mt-6 mb-2"},[_vm._v(" Set check schedule "),_c('span',{staticClass:"text-bgPrimary"},[_vm._v("*")])])}]

export { render, staticRenderFns }