


















































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import MultiLevel from '@/app/ui/components/MultiLevel/index.vue'
import FormLevel from '../components/FormLevel/index.vue'
import MembershipTextInput from '../components/MembershipTextInput/index.vue'
import CheckBoxCheckedIcon from '@/app/ui/assets/check_box_checked.vue'
import CheckBoxUncheckedIcon from '@/app/ui/assets/check_box_unchecked.vue'
import MembershipDatePicker from '../components/MembershipDatePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ModalConfirmation from '../components/ModalConfirmation/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import {
  minValue,
  required,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import dayjs from 'dayjs'
import controller from '@/app/ui/controllers/LoyaltyProgramMembershipController'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'
import { validationMixin } from 'vuelidate'

interface IValidationRule {
  form: {
    sttPrefix: {
      required: () => ValidationRule
    }
    shipmentID: {
      required: ValidationRule
    }
    packageStatus: {
      required: () => ValidationRule
    }
    month: {
      required: () => ValidationRule
      minValue: ValidationRule
    }
    startDate: {
      required: () => ValidationRule
    }
    startTime: {
      required: () => ValidationRule
    }
    endDate: {
      required: () => ValidationRule
    }
    endTime: {
      required: () => ValidationRule
    }
    levelRule: {
      $each: {
        isMoreThanPrevLevel: (val: IFormLevel, values: IFormLevel[]) => boolean
        name: {
          required: () => ValidationRule
        }
        minAmount: {
          required: () => ValidationRule
          minValue: ValidationRule
        }
      }
    }
  }
}

export interface IFormLevel {
  id?: number | string
  name: string
  minAmount: string | number
}

@Component({
  mixins: [validationMixin],
  components: {
    MultiLevel,
    FormLevel,
    MembershipTextInput,
    CheckBoxCheckedIcon,
    CheckBoxUncheckedIcon,
    MembershipDatePicker,
    Button,
    ModalConfirmation,
  },
})
export default class CreateLoyaltyProgramMembership extends Vue {
  form = {
    sttPrefix: [],
    shipmentID: [],
    isNonCA: false,
    packageStatus: [],
    month: '',
    date1: '',
    date2: '',
    date3: '',
    date4: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    levelRule: [
      ...new Array(5).fill('').map(
        (item, index): Record<string, string | number | null> => {
          return {
            id: index,
            name: '',
            minAmount: null,
          }
        }
      ),
    ],
  }
  modalConfirmation = {
    visible: false,
    title: '',
    subtitle: '',
    buttonOK: '',
  }
  hasChanged = {
    sttPrefix: false,
    shipmentID: false,
    packageStatus: false,
    month: false,
    date1: false,
    date2: false,
    date3: false,
    date4: false,
    startDate: false,
    startTime: false,
    endDate: false,
    endTime: false,
    levelRule: [
      ...Array(5)
        .fill('')
        .map(() => {
          return {
            name: false,
            minAmount: false,
          }
        }),
    ],
  }
  todayDate = dayjs().format('YYYY-MM-DD')
  controller = controller
  ObjectMultiLevel = [
    {
      prefix: 'Level 1 (Lowest)',
      isOpen: false,
      isError: this.isLevelError(0, 'all'),
    },
    {
      prefix: 'Level 2',
      isOpen: false,
      isError: this.isLevelError(1, 'all'),
    },
    {
      prefix: 'Level 3',
      isOpen: false,
      isError: this.isLevelError(2, 'all'),
    },
    {
      prefix: 'Level 4',
      isOpen: false,
      isError: this.isLevelError(3, 'all'),
    },
    {
      prefix: 'Level 5 (Highest)',
      isOpen: false,
      isError: this.isLevelError(4, 'all'),
    },
  ]

  @Validations()
  validations(): IValidationRule {
    return {
      form: {
        sttPrefix: {
          required,
        },
        shipmentID: {
          required: requiredIf(() => {
            return !this.form.isNonCA
          }),
        },
        packageStatus: {
          required,
        },
        month: {
          required,
          minValue: minValue(1),
        },
        startDate: {
          required,
        },
        startTime: {
          required,
        },
        endDate: {
          required,
        },
        endTime: {
          required,
        },
        levelRule: {
          $each: {
            isMoreThanPrevLevel: (val: IFormLevel, values: IFormLevel[]) =>
              this.isMoreThanPrevLevel(val, values),
            name: {
              required,
            },
            minAmount: {
              required,
              minValue: minValue(0),
            },
          },
        },
      },
    }
  }

  private isMoreThanPrevLevel(val: IFormLevel, values: IFormLevel[]): boolean {
    const currIdx = values.findIndex((item: IFormLevel) => item.id === val.id)
    const prevSchedule = values[currIdx - 1]?.minAmount
    if (prevSchedule) {
      return val.minAmount > prevSchedule
    }
    if (currIdx !== 0) {
      return val.minAmount > 0
    }
    return true
  }

  private isLevelError(
    index: number,
    type: 'all' | 'name' | 'minAmount'
  ): boolean | string | undefined {
    const v = this.$v
    if (v) {
      const form = v.form
      if (form) {
        const levelRuleIndex = form.levelRule
        if (levelRuleIndex) {
          const eachIndex = levelRuleIndex.$each[index]
          if (eachIndex) {
            switch (type) {
              case 'all':
                return (
                  (eachIndex.$invalid &&
                    this.hasChanged.levelRule[index].name) ||
                  this.hasChanged.levelRule[index].minAmount
                )
              case 'name':
                if (
                  eachIndex.name.$invalid &&
                  this.hasChanged.levelRule[index].name
                ) {
                  return 'Must be filled'
                }
                return undefined
              case 'minAmount':
                if (
                  eachIndex.minAmount.$invalid &&
                  this.hasChanged.levelRule[index].minAmount
                ) {
                  return 'Must be filled'
                }
                if (
                  !eachIndex.isMoreThanPrevLevel &&
                  this.hasChanged.levelRule[index].minAmount
                ) {
                  return 'Minimum amount here should be higher than on the previous level'
                }
                return undefined
            }
          }
        }
      }
    }
    return false
  }

  private toogleNonCA(): void {
    this.form.isNonCA = !this.form.isNonCA
  }

  get moreStartDate(): string {
    return dayjs(this.form.startDate || this.todayDate).format('YYYY-MM-DD')
  }

  public showModalSubmit(): void {
    if (
      !this.$v.form.$invalid &&
      !this.hasDuplicateMonth('all') &&
      !this.isDateScheduleEmpty()
    ) {
      this.modalConfirmation = {
        visible: true,
        title: 'Want to Start Program?',
        subtitle: 'Make sure the data are correct before you start program',
        buttonOK: 'Start',
      }
    } else {
      this.$notify({
        title: 'Create Loyalty Membership Failed',
        text: 'Mohon cek kembali setiap kolom dan pastikan semua telah terisi',
        type: 'error',
        duration: 5000,
      })
      this.hasChanged = {
        sttPrefix: true,
        shipmentID: true,
        packageStatus: true,
        month: true,
        date1: true,
        date2: true,
        date3: true,
        date4: true,
        startDate: true,
        startTime: true,
        endDate: true,
        endTime: true,
        levelRule: [
          ...new Array(5).fill('').map(() => {
            return {
              name: true,
              minAmount: true,
            }
          }),
        ],
      }
      this.ObjectMultiLevel = this.ObjectMultiLevel.map((item, index) => {
        return {
          ...item,
          isOpen: Boolean(this.isLevelError(index, 'all')),
          isError: this.isLevelError(index, 'all'),
        }
      })
    }
  }

  public showModalCancel(): void {
    this.modalConfirmation = {
      visible: true,
      title: 'Cancel Create Program?',
      subtitle:
        'If you cancel, data to your program membership will not be saved',
      buttonOK: 'Yes',
    }
  }

  public closeModal(): void {
    this.modalConfirmation = {
      visible: false,
      title: '',
      subtitle: '',
      buttonOK: '',
    }
  }

  public onSubmit(): void {
    if (this.modalConfirmation.buttonOK === 'Start') {
      const payload = {
        sttPrefix: this.form.sttPrefix,
        shipmentID: this.form.shipmentID,
        isNonCA: this.form.isNonCA,
        packageStatus: this.form.packageStatus,
        month: Number(this.form.month),
        startDate: this.form.startDate,
        startTime: this.form.startTime,
        endDate: this.form.endDate,
        endTime: this.form.endTime,
        date: [
          {
            date: this.form.date1.split('-')[2],
            month: this.form.date1.split('-')[1],
          },
          {
            date: this.form.date2.split('-')[2],
            month: this.form.date2.split('-')[1],
          },
          {
            date: this.form.date3.split('-')[2],
            month: this.form.date3.split('-')[1],
          },
          {
            date: this.form.date4.split('-')[2],
            month: this.form.date4.split('-')[1],
          },
        ].filter(item => {
          return item.date
        }),
        formLevel: this.form.levelRule.map(item => {
          return {
            name: String(item?.name),
            minAmount: Number(item?.minAmount),
            minNumber: null,
          }
        }),
      }

      controller.create(payload)
    } else {
      this.$router.push('/loyalty-program/membership')
    }
  }

  @Watch('controller.isSuccessSave')
  onTriggerSuccessSave(val: boolean): void {
    if (val) {
      this.$router.push('/loyalty-program/membership')
    }
  }

  private hasDuplicateMonth(
    type: 'all' | 'date1' | 'date2' | 'date3' | 'date4'
  ): boolean {
    const destructDate = (date: string): string | undefined => {
      return (
        date
          .split('-')
          .splice(1, 2)
          .join('/') || undefined
      )
    }
    const dataArray = [
      destructDate(this.form.date1),
      destructDate(this.form.date2),
      destructDate(this.form.date3),
      destructDate(this.form.date4),
    ]
    if (type !== 'all') {
      const dataFilter = dataArray
        .filter((item, index) => {
          return index !== Number(type.replace(/date/, '')) - 1
        })
        .filter(item => {
          return item !== undefined
        })
      return dataFilter.includes(destructDate(this.form[type]))
    }
    const isUnique = dataArray
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .filter(a => {
        return a !== undefined
      })
    const dataClean = dataArray.filter(item => {
      return item !== undefined
    })
    return dataClean.length !== isUnique.length
  }

  private onInputLevel(
    val: string,
    index: number,
    type: 'name' | 'minAmount'
  ): void {
    this.form.levelRule[index][type] = val
    this.hasChanged.levelRule[index][type] = true
  }

  private isDateScheduleEmpty(): boolean {
    const dataArray = [
      this.form.date1,
      this.form.date2,
      this.form.date3,
      this.form.date4,
    ].filter(item => {
      return item !== ''
    })
    return dataArray.length <= 0
  }

  private NolIgnore(e: number | string): void {
    if (e === 0) {
      this.form.month = ''
    }
  }

  @Watch('form.levelRule', { deep: true })
  onFormLevelChange(): void {
    const newObjectMultiLevel = this.ObjectMultiLevel.map((item, index) => {
      return {
        ...item,
        isError: this.isLevelError(index, 'all'),
        isOpen: !item.isOpen
          ? Boolean(this.isLevelError(index, 'all'))
          : item.isOpen,
      }
    })
    this.ObjectMultiLevel = newObjectMultiLevel
  }

  private toggleMultiLevel(index: number): void {
    this.ObjectMultiLevel[index].isOpen = !this.ObjectMultiLevel[index].isOpen
  }

  private openMultiLevel(index: number): void {
    this.ObjectMultiLevel[index].isOpen = true
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.CREATE_LOYALTY_PROGRAM_MEMBERSHIP)
  }
}
