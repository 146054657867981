

































import { Component, Prop, Vue } from 'vue-property-decorator'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  components: {
    DateTimePicker,
    IconWarningTriangle,
  },
})
export default class MembershipDatePicker extends Vue {
  @Prop({ required: true }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: false }) private removeYear!: boolean

  private calendarOpen(): void {
    if (this.removeYear) {
      const pick = document.getElementById(
        `${this.$attrs.id || ''}-picker-container-DatePicker`
      )
      pick
        ?.getElementsByClassName('h-100 flex align-center flex-1 flex')[1]
        ?.remove()
    }
  }
}
