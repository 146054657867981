import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5198f245&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./style.scss?vue&type=style&index=0&id=5198f245&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5198f245",
  null
  
)

export default component.exports