import {
  CreateLoyaltyProgramMembershipRequestInterface,
  UpdateLoyaltyProgramMembershipRequestInterface,
} from '../contracts/LoyaltyProgramMembershipRequest'

export interface MembershipProgram {
  start_date: string
  end_date: string
  prefix_stt_shipments: Array<string>
  prefix_shipment_ids: Array<string>
  is_include_stt_non_ca: boolean
  package_statuses: Array<string>
  backward_month_period: number | string
  status?: string
}

export interface MembershipProgramDateSchedule {
  date: number
  month: number
}

export interface MembershipProgramLevel {
  level_name: string
  min_amount_transaction: number
  min_number_transaction?: number | null
  id?: number | null
}

export class CreateLoyaltyProgramMembershipRequest
  implements CreateLoyaltyProgramMembershipRequestInterface {
  private readonly membership_program?: MembershipProgram
  private readonly membership_program_date_schedules?: MembershipProgramDateSchedule[]
  private readonly membership_program_levels?: MembershipProgramLevel[]

  constructor(
    membershipProgram?: MembershipProgram,
    membershipProgramDateSchedules?: MembershipProgramDateSchedule[],
    membershipProgramLevels?: MembershipProgramLevel[]
  ) {
    this.membership_program = membershipProgram
    this.membership_program_date_schedules = membershipProgramDateSchedules
    this.membership_program_levels = membershipProgramLevels
  }
  toPayload(): string {
    return JSON.stringify({
      membership_program: this.membership_program,
      membership_program_date_schedules: this.membership_program_date_schedules,
      membership_program_levels: this.membership_program_levels,
    })
  }
}

export class UpdateLoyaltyProgramMembershipRequest
  extends CreateLoyaltyProgramMembershipRequest
  implements UpdateLoyaltyProgramMembershipRequestInterface {
  constructor(
    membershipProgram?: MembershipProgram,
    membershipProgramDateSchedules?: MembershipProgramDateSchedule[],
    membershipProgramLevels?: MembershipProgramLevel[]
  ) {
    super(
      membershipProgram,
      membershipProgramDateSchedules,
      membershipProgramLevels
    )
  }
}
